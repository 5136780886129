<template>
  <div class="question-wrap">
    <pageTitle pageTitle="表单">
      <div slot="content">
        表单模板可根据不同的使用场景调用到活动下问卷、资料下载或站点管理中进行使用，修改不影响已被引用的数据
      </div>
    </pageTitle>
    <div class="head-operat" v-show="total || isSearch">
      <vh-button
        size="medium"
        type="primary"
        round
        class="length104 head-btn set-upload"
        v-preventReClick
        @click="addQuestion"
      >
        创建表单
      </vh-button>
      <vh-button
        size="medium"
        round
        plain
        class="transparent-btn"
        @click="deleteAll(null)"
        :disabled="!selectChecked.length"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        class="search-tag"
        placeholder="请输入表单名称"
        v-model="keyword"
        :clearable="true"
        @clear="searchTableList"
        @keyup.enter.native="searchTableList"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchTableList"></i>
      </vh-input>
    </div>
    <div class="no-live" v-if="!total && !isSearch">
      <noData :nullType="'nullData'" :text="'您还没有表单，快来创建吧！'">
        <vh-button type="primary" round @click="addQuestion" v-preventReClick>创建表单</vh-button>
      </noData>
    </div>
    <div class="question-list" v-show="total || isSearch">
      <table-list
        ref="tableList"
        :manageTableData="tableData"
        :tabelColumnLabel="tabelColumn"
        :tableRowBtnFun="tableRowBtnFun"
        :totalNum="total"
        @onHandleBtnClick="onHandleBtnClick"
        :width="210"
        @getTableList="getTableList"
        @changeTableCheckbox="changeTableCheckbox"
      ></table-list>
      <noData :nullType="'search'" v-if="!total"></noData>
    </div>
    <template v-if="isShowQuestion">
      <div class="show-question" @click="isShowQuestion = false">
        <div class="show-main" @click.stop="isShowQuestion = true">
          <span class="close-btn">
            <i class="vh-icon-close" @click.stop="isShowQuestion = false"></i>
          </span>
          <vue-scroll :ops="ops">
            <div class="question_main">
              <pre-question :questionId="questionId"></pre-question>
              <div class="submit-footer">
                <vh-button class="length152" type="primary" disabled size="medium" round>
                  提交
                </vh-button>
              </div>
              <!-- 隐私协议合规（资料管理下） -->
              <!-- <privacy-select scene="question" compType="2"></privacy-select> -->
            </div>
          </vue-scroll>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import preQuestion from '@/components/Question/preQuestion';
  import { sessionOrLocal } from '@/utils/utils';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import PrivacySelect from '@/views/Login/components/privacy-select.vue';
  export default {
    name: 'question',
    data() {
      return {
        ops: {
          //滚动条配置
          bar: {
            background: 'rgba(0,0,0,0.3)'
          }
        },
        total: 0,
        isSearch: false, //是否是搜索
        selectChecked: [],
        keyword: '',
        questionId: '',
        isShowQuestion: false,
        tabelColumn: [
          {
            label: '表单名称',
            key: 'title',
            customTooltip: true
          },
          {
            label: '更新时间',
            key: 'updated_at'
          },
          {
            label: '题目数量',
            key: 'topic_num'
          }
        ],
        tableRowBtnFun: [
          { name: '预览', methodName: 'preview' },
          { name: '复制', methodName: 'cope' },
          { name: '编辑', methodName: 'edit' },
          { name: '删除', methodName: 'del' }
        ],
        tableData: []
      };
    },
    components: {
      PageTitle,
      preQuestion,
      noData,
      PrivacySelect
    },
    mounted() {
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.getTableList();
    },
    methods: {
      onHandleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      clear(e) {
        console.log(e, '?????????????????');
        e.stopPropagation();
      },
      searchTableList() {
        if (this.keyword) {
          this.$vhall_paas_port({
            k: 100532,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getTableList('search');
      },
      getTableList(params) {
        let pageInfo = this.$refs.tableList.pageInfo; //获取分页信息
        let formParams = {
          user_id: this.userId,
          keyword: this.keyword
        };
        if (params == 'search') {
          pageInfo.pageNum = 1;
          pageInfo.pos = 0;
        }
        this.isSearch = this.keyword ? true : false;
        let obj = Object.assign({}, pageInfo, formParams);
        this.$fetch('getQuestionList', this.$params(obj)).then(res => {
          this.total = res.data.total;
          this.tableData = res.data.list || [];
        });
      },
      // 预览
      preview(that, { rows }) {
        console.log('预览', rows);
        that.isShowQuestion = true;
        that.$vhall_paas_port({
          k: 100531,
          data: {
            business_uid: that.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        // document.querySelector('.section__main').style.minHeight = 640 + 'px'
        that.questionId = rows.question_id;
      },
      // 复制
      cope(that, { rows }) {
        const params = { survey_id: rows.question_id };
        if (this.alias) {
          params.alias = this.alias;
        }
        that.$fetch('copyQuestion', params).then(res => {
          that.$vhall_paas_port({
            k: 100528,
            data: {
              business_uid: that.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          that.$vhMessage({
            message: res.code == 200 ? '复制成功' : '复制失败',
            showClose: true,
            type: res.code == 200 ? 'success' : 'error',
            customClass: 'zdy-info-box'
          });
          that.getTableList();
        });
      },
      // 编辑
      edit(that, { rows }) {
        console.log('编辑', rows);
        that.$router.push({
          path: '/interactive/addQuestion',
          query: {
            questionId: rows.question_id,
            type: 1,
            alias: rows.alias
          }
        });
      },
      // 删除
      del(that, { rows }) {
        that.deleteConfirm(rows.question_id, 2);
      },
      deleteConfirm(id, index) {
        this.$vhConfirm('删除后，此表单将无法使用，确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.$fetch('deleteQuestion', { survey_ids: id })
              .then(res => {
                this.$vhall_paas_port({
                  k: index == 1 ? 100530 : 100529,
                  data: {
                    business_uid: this.userId,
                    user_id: '',
                    webinar_id: '',
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
                this.getTableList('search');
                this.$vhMessage({
                  message: `删除成功`,
                  showClose: true,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || '删除失败',
                  showClose: true,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          })
          .catch(() => {
            this.$vhMessage({
              message: `已取消删除`,
              showClose: true,
              type: 'info',
              customClass: 'zdy-info-box'
            });
          });
      },
      deleteAll(id) {
        id = this.selectChecked.join(',');
        this.deleteConfirm(id, 1);
      },
      // 选中
      changeTableCheckbox(val) {
        this.selectChecked = val.map(val => val.question_id);
        console.log(this.selectChecked);
      },
      addQuestion() {
        this.$router.push({
          path: '/interactive/addQuestion',
          query: {
            type: 1 //用来判断区分哪里的问卷
          }
        });
      },
      closeClose(done) {
        this.isShowQuestion = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  .question-list {
    width: 100%;
    height: 100%;
    .layout--right--main();
    .padding-table-list();
    .min-height();
  }

  .question-wrap {
    height: 100%;
    width: 100%;

    .head-operat {
      margin-bottom: 20px;
      .head-btn {
        display: inline-block;
      }

      .search-tag {
        float: right;
        width: 220px;
      }
    }
    .show-question {
      position: fixed;
      z-index: 1001;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      .show-main {
        height: 90%;
        border-radius: 4px;
        background: #fff;
        position: relative;
        z-index: 101;
        .question_main {
          position: relative;
          width: 750px;
          padding-bottom: 24px;
        }
        .close-btn {
          z-index: 100;
          position: absolute;
          top: 16px;
          right: 16px;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 50%;
          padding: 5px;
        }
        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
        .submit-footer {
          text-align: center;
          padding-top: 20px;
        }
      }
    }
  }
</style>
